<ng-container *ngIf="item">
  <!-- Main container -->
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h1 class="title is-2 has-text-weight-light" [title]="item.name">
          {{ item.title }}
        </h1>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item">
        <a class="button is-danger is-outlined" [caseAction]="deleteAction">
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-delete"></i>
          </span>
          <span>Delete</span>
        </a>
      </p>
      <p class="level-item">
        <a
          class="button is-link"
          [routerLink]="['/policy-initiatives/' + item.id + '/edit']"
        >
          <span class="icon is-small">
            <i aria-hidden="true" class="icon icon-edit"></i>
          </span>
          <span> Edit </span>
        </a>
      </p>
    </div>
  </nav>

  <div class="columns is-multiline">
    <div class="column is-8">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p
                class="is-uppercase has-text-grey-darker has-text-weight-medium is-size-6"
              >
                Description
              </p>
              <p class="is-size-6">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <ng-container *ngFor="let taxonomy of policyInitiativeRelations">
              <h4
                class="is-uppercase has-text-dark has-text-weight-medium mt-1 is-size-6"
              >
                {{ taxonomy.label }}
              </h4>
              <ng-container *ngIf="!item[taxonomy.propName].length">
                <em>-</em></ng-container
              >
              <ng-container *ngIf="item[taxonomy.propName].length">
                <ul>
                  <li *ngFor="let relation of item[taxonomy.propName]">
                    <span class="is-size-6">{{ relation.name }}</span>
                  </li>
                </ul></ng-container
              >
              <hr />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
