import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Entity } from '../../../../../../shared/enums/entity.enum'
import { Status } from '../../../../../../shared/enums/status.enum'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ActionType } from '../../../abacus/enums/action-type.enum'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { ResourceMode } from '../../../abacus/enums/resource-mode.enum'
import { Action } from '../../../abacus/interfaces/actions/action.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { toolUseCaseDefinition } from '../tool-use-case.definition'

@Component({
  selector: 'app-tool-use-case-detail',
  templateUrl: './tool-use-case-detail.component.html',
  styleUrls: ['./tool-use-case-detail.component.scss']
})
export class ToolUseCaseDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  definition: ResourceDefinition = toolUseCaseDefinition

  publishAction: Action
  deleteAction: Action
  rejectAction: Action
  pendingAction: Action

  Status = Status
  Entity = Entity

  tucRelations = [
    { label: 'Purposes', propName: 'purposes' },
    { label: 'Target Sectors', propName: 'targetSectors' },
    { label: 'Objectives', propName: 'objectives' },
    { label: 'Organisations', propName: 'organisations' },
    { label: 'Impacted Stakeholders', propName: 'impactedStakeholders' },
    { label: 'Target Users', propName: 'targetUsers' },
    { label: 'Countries', propName: 'countries' },
    { label: 'Risk Management Stages', propName: 'risks' }
  ]

  itemRelations = [
    ...this.tucRelations.map((relation) => relation.propName),
    'tool'
  ]

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()

    this.deleteAction = {
      type: ActionType.Delete,
      delete: {
        itemToDelete: this.item,
        definition: toolUseCaseDefinition,
        redirectTo: '/tool-use-cases'
      }
    }
    this.publishAction = {
      type: ActionType.Patch,
      patch: {
        path: `tool-use-cases/${this.item.id}/status`,
        body: { status: Status.Published },
        successMessage: 'The tool use case has been published !',
        errorMessage: 'Error: Tool use case could not be published'
      }
    }
    this.rejectAction = {
      type: ActionType.OpenCreateEditModal,
      openCreateEditModal: {
        title: `Reject the submission`,
        helpText: `You can provide a reason for rejecting the submission.`,
        definition: toolUseCaseDefinition,
        mode: ResourceMode.Patch,
        item: this.item,
        patchURL: `tool-use-cases/${this.item.id}/status`,
        fields: [
          {
            label: 'Rejection comment',
            property: 'rejectReason',
            placeholder: 'Explain why you are rejecting this submission.',
            className: 'is-12',
            inputType: InputType.Textarea
          },
          {
            label: 'Status',
            property: 'status',
            forcedValue: Status.Rejected,
            inputType: InputType.Select,
            hidden: true
          }
        ]
      }
    }
    this.pendingAction = {
      type: ActionType.Patch,
      patch: {
        path: `tool-use-cases/${this.item.id}/status`,
        body: {
          status: Status.Pending
        },
        successMessage: 'The tool use case has been switch to pending !',
        errorMessage: 'Error: Tool use case could not be rejected'
      }
    }
  }
}
