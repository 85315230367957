import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { policyInitiativeCreateEditFields } from './policy-initiative-create-edit.fields'
import { policyInitiativeDefinition } from '../policy-initiative.definition'

@Component({ template: caseCreateEditTemplate })
export class PolicyInitiativeCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = policyInitiativeDefinition
  fields: Field[] = policyInitiativeCreateEditFields

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
