import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Entity } from '../../../../../../shared/enums/entity.enum'
import { Status } from '../../../../../../shared/enums/status.enum'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ActionType } from '../../../abacus/enums/action-type.enum'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { ResourceMode } from '../../../abacus/enums/resource-mode.enum'
import { Action } from '../../../abacus/interfaces/actions/action.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { metricDefinition } from '../metric.definition'

@Component({
  selector: 'app-metric-detail',
  templateUrl: './metric-detail.component.html',
  styleUrls: ['./metric-detail.component.scss']
})
export class MetricDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  definition: ResourceDefinition = metricDefinition

  publishAction: Action
  deleteAction: Action
  rejectAction: Action
  pendingAction: Action

  Status = Status

  Entity = Entity

  metricRelations = [
    { label: 'Objectives', propName: 'objectives' },
    { label: 'Usage Rights', propName: 'usageRights' },
    { label: 'Risk Management Stages', propName: 'risks' },
    { label: 'Metric types', propName: 'metricTypes' },
    { label: 'Core application areas', propName: 'coreApplicationAreas' },
    { label: 'Purposes', propName: 'purposes' },
    { label: 'Target users', propName: 'targetUsers' },
    { label: 'Lifecycles', propName: 'lifecycles' },
    { label: 'Target sectors', propName: 'targetSectors' }
  ]

  itemRelations = [
    ...this.metricRelations.map((relation) => relation.propName),
    'metricUseCases'
  ]

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    private componentResourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()

    this.deleteAction = {
      type: ActionType.Delete,
      delete: {
        itemToDelete: this.item,
        definition: metricDefinition,
        redirectTo: '/metrics'
      }
    }
    this.publishAction = {
      type: ActionType.Patch,
      patch: {
        path: `metrics/${this.item.id}/status`,
        body: { status: Status.Published },
        successMessage: 'The metric has been published !',
        errorMessage: 'Error: Metric could not be published'
      }
    }
    this.rejectAction = {
      type: ActionType.OpenCreateEditModal,
      openCreateEditModal: {
        title: `Reject the submission`,
        helpText: `You can provide a reason for rejecting the submission.`,
        definition: metricDefinition,
        mode: ResourceMode.Patch,
        item: this.item,
        patchURL: `metrics/${this.item.id}/status`,
        fields: [
          {
            label: 'Rejection comment',
            property: 'rejectReason',
            placeholder: 'Explain why you are rejecting this submission.',
            className: 'is-12',
            inputType: InputType.Textarea
          },
          {
            label: 'Status',
            property: 'status',
            forcedValue: Status.Rejected,
            inputType: InputType.Select,
            hidden: true
          }
        ]
      }
    }

    this.pendingAction = {
      type: ActionType.Patch,
      patch: {
        path: `metrics/${this.item.id}/status`,
        body: { status: Status.Pending },
        successMessage: 'The metric has been switch to pending !',
        errorMessage: 'Error: Metric could not be switch to pending.'
      }
    }
  }
}
