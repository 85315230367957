import { YieldType } from '../../abacus/enums/yield-type.enum'
import { Yield } from '../../abacus/interfaces/yield.interface'

export const policyInitiativeYields: Yield[] = [
  {
    label: 'Title',
    property: 'title'
  },
  {
    label: 'Submission date',
    property: 'createdAt',
    type: YieldType.Date
  }
]
