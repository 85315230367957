import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'

import { countryDefinition } from '../../country/country.definition'

export const policyInitiativeCreateEditFields: Field[] = [
  {
    label: 'Title',
    placeholder: 'Enter the policy initiative title...',
    property: 'title',
    className: 'is-6',
    inputType: InputType.Text,
    required: true
  },
  {
    label: 'Description',
    placeholder: 'Enter the description...',
    property: 'description',
    className: 'is-12',
    inputType: InputType.Text
  },
  {
    label: 'Objective',
    placeholder: 'Enter the objective...',
    property: 'objective',
    className: 'is-12',
    inputType: InputType.Text
  },
  {
    label: 'Countries',
    properties: {
      countryIds: 'countryIds'
    },
    retrievedItemProperties: {
      countryIds: 'countryIds'
    },
    className: 'is-6',
    searchResources: [countryDefinition],
    inputType: InputType.MultiSearch
  }
]
