export const environment = {
  production: true,
  envName: 'production',
  appName: 'OECD.AI Admin Dashboard',
  baseUrl: 'https://oecd-ai.case-dashboard.buddyweb.fr',
  apiBaseUrl: 'https://oecd-ai.case-api.buddyweb.fr',
  storagePath: 'https://oecd-ai.case-api.buddyweb.fr/storage',
  tokenName: 'oecdAIToken',
  tokenAllowedDomains: ['oecd-ai.case-api.buddyweb.fr'],
  enableBugsnag: true,
  bugsnagApiKey: 'c7f04b4b37cbe41d375491e8497c4509',
  isOnboarding: false
}
