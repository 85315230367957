import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

import { environment } from '../environments/environment'
import { AbacusModule } from './abacus/abacus.module'
import { AuthService } from './abacus/services/auth.service'
import { EventService } from './abacus/services/event.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ChangeHistoryComponent } from './components/change-history/change-history.component'
import { HomeComponent } from './pages/home/home.component'
import { ApproachCreateEditComponent } from './resources/approach/approach-create-edit/approach-create-edit.component'
import { ApproachListComponent } from './resources/approach/approach-list/approach-list.component'
import { BenefitCreateEditComponent } from './resources/benefit/benefit-create-edit/benefit-create-edit.component'
import { BenefitListComponent } from './resources/benefit/benefit-list/benefit-list.component'
import { CoreApplicationAreaCreateEditComponent } from './resources/core-application-area/core-application-area-create-edit/corea-application-area-create-edit.component'
import { CoreApplicationListComponent } from './resources/core-application-area/core-application-area-list/core-application-area-list.component'
import { CountryCreateEditComponent } from './resources/country/country-create-edit/country-create-edit.component'
import { CountryListComponent } from './resources/country/country-list/country-list.component'
import { EnforcementCreateEditComponent } from './resources/enforcement/enforcement-create-edit/enforcement-create-edit.component'
import { EnforcementListComponent } from './resources/enforcement/enforcement-list/enforcement-list.component'
import { GeographicalScopeCreateEditComponent } from './resources/geographical-scope/geographical-scope-create-edit/geographical-scope-create-edit.component'
import { GeographicalScopeListComponent } from './resources/geographical-scope/geographical-scope-list/geographical-scope-list.component'
import { ImpactedStakeholderCreateEditComponent } from './resources/impacted-stakeholder/impacted-stakeholder-create-edit/impacted-stakeholder-create-edit.component'
import { ImpactedStakeholderListComponent } from './resources/impacted-stakeholder/impacted-stakeholder-list/impacted-stakeholder-list.component'
import { LicenseCreateEditComponent } from './resources/license/license-create-edit/license-create-edit.component'
import { LicenseListComponent } from './resources/license/license-list/license-list.component'
import { LifecycleCreateEditComponent } from './resources/lifecycle/lifecycle-create-edit/lifecycle-create-edit.component'
import { LifecycleListComponent } from './resources/lifecycle/lifecycle-list/lifecycle-list.component'
import { MaturityCreateEditComponent } from './resources/maturity/maturity-create-edit/maturity-create-edit.component'
import { MaturityListComponent } from './resources/maturity/maturity-list/maturity-list.component'
import { MetricTypeCreateEditComponent } from './resources/metric-type/metric-type-create-edit/metric-type-create-edit.component'
import { MetricTypeListComponent } from './resources/metric-type/metric-type-list/metric-type-list.component'
import { MetricUseCaseCreateEditComponent } from './resources/metric-use-case/metric-use-case-create-edit/metric-use-case-create-edit.component'
import { MetricUseCaseDetailComponent } from './resources/metric-use-case/metric-use-case-detail/metric-use-case-detail.component'
import { MetricUseCaseEditForSubmitterComponent } from './resources/metric-use-case/metric-use-case-edit-for-submitter/metric-use-case-edit-for-submitter.component'
import { MetricUseCaseListComponent } from './resources/metric-use-case/metric-use-case-list/metric-use-case-list.component'
import { MetricCreateEditComponent } from './resources/metric/metric-create-edit/metric-create-edit.component'
import { MetricDetailComponent } from './resources/metric/metric-detail/metric-detail.component'
import { MetricEditForSubmitterComponent } from './resources/metric/metric-edit-for-submitter/metric-edit-for-submitter.component'
import { MetricListComponent } from './resources/metric/metric-list/metric-list.component'
import { ObjectiveCreateEditComponent } from './resources/objective/objective-create-edit/objective-create-edit.component'
import { ObjectiveListComponent } from './resources/objective/objective-list/objective-list.component'
import { OrganisationCreateEditComponent } from './resources/organisation/organisation-create-edit/organisation-create-edit.component'
import { OrganisationListComponent } from './resources/organisation/organisation-list/organisation-list.component'
import { PeopleInvolvedCreateEditComponent } from './resources/people-involved/people-involved-create-edit/people-involved-create-edit.component'
import { PeopleInvolvedListComponent } from './resources/people-involved/people-involved-list/people-involved-list.component'
import { ProgrammingLanguageCreateEditComponent } from './resources/programming-language/programming-language-create-edit/programming-language-create-edit.component'
import { ProgrammingLanguageListComponent } from './resources/programming-language/programming-language-list/programming-language-list.component'
import { PurposeCreateEditComponent } from './resources/purpose/purpose-create-edit/purpose-create-edit.component'
import { PurposeListComponent } from './resources/purpose/purpose-list/purpose-list.component'
import { RequiredSkillCreateEditComponent } from './resources/required-skill/required-skill-create-edit/required-skill-create-edit.component'
import { RequiredSkillListComponent } from './resources/required-skill/required-skill-list/required-skill-list.component'
import { RiskCreateEditComponent } from './resources/risk/risk-create-edit/risk-create-edit.component'
import { RiskListComponent } from './resources/risk/risk-list/risk-list.component'
import { StakeholderGroupCreateEditComponent } from './resources/stakeholder-group/stakeholder-group-create-edit/stakeholder-group-create-edit.component'
import { StakeholderGroupListComponent } from './resources/stakeholder-group/stakeholder-group-list/stakeholder-group-list.component'
import { TagCreateEditComponent } from './resources/tag/tag-create-edit/tag-create-edit.component'
import { TagListComponent } from './resources/tag/tag-list/tag-list.component'
import { TargetGroupCreateEditComponent } from './resources/target-group/target-group-create-edit/target-group-create-edit.component'
import { TargetGroupListComponent } from './resources/target-group/target-group-list/target-group-list.component'
import { TargetSectorCreateEditComponent } from './resources/target-sector/target-sector-create-edit/target-sector-create-edit.component'
import { TargetSectorListComponent } from './resources/target-sector/target-sector-list/target-sector-list.component'
import { TargetUserCreateEditComponent } from './resources/target-user/target-user-create-edit/target-user-create-edit.component'
import { TargetUserListComponent } from './resources/target-user/target-user-list/target-user-list.component'
import { TechnologyPlatformCreateEditComponent } from './resources/technology-platform/technology-platform-create-edit/technology-platform-create-edit.component'
import { TechnologyPlatformListComponent } from './resources/technology-platform/technology-platform-list/technology-platform-list.component'
import { ToolTypeCreateEditComponent } from './resources/tool-type/tool-type-create-edit/tool-type-create-edit.component'
import { ToolTypeListComponent } from './resources/tool-type/tool-type-list/tool-type-list.component'
import { ToolUseCaseCreateEditComponent } from './resources/tool-use-case/tool-use-case-create-edit/tool-use-case-create-edit.component'
import { ToolUseCaseDetailComponent } from './resources/tool-use-case/tool-use-case-detail/tool-use-case-detail.component'
import { ToolUseCaseEditForSubmitterComponent } from './resources/tool-use-case/tool-use-case-edit-for-submitter/tool-use-case-edit-for-submitter.component'
import { ToolUseCaseListComponent } from './resources/tool-use-case/tool-use-case-list/tool-use-case-list.component'
import { ToolCreateEditComponent } from './resources/tool/tool-create-edit/tool-create-edit.component'
import { ToolDetailComponent } from './resources/tool/tool-detail/tool-detail.component'
import { ToolEditForSubmitterComponent } from './resources/tool/tool-edit-for-submitter/tool-edit-for-submitter.component'
import { ToolListComponent } from './resources/tool/tool-list/tool-list.component'
import { UsageRightCreateEditComponent } from './resources/usage-right/usage-right-create-edit/usage-right-create-edit.component'
import { UsageRightListComponent } from './resources/usage-right/usage-right-list/usage-right-list.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit.component'
import { UserListComponent } from './resources/user/user-list.component'
import { ValidityCreateEditComponent } from './resources/validity/validity-create-edit/validity-create-edit.component'
import { ValidityListComponent } from './resources/validity/validity-list/validity-list.component';
import { PolicyInitiativeCreateEditComponent } from './resources/policy-initiative/policy-initiative-create-edit/policy-initiative-create-edit.component'
import { PolicyInitiativeDetailComponent } from './resources/policy-initiative/policy-initiative-detail/policy-initiative-detail.component'
import { PolicyInitiativeListComponent } from './resources/policy-initiative/policy-initiative-list/policy-initiative-list.component'

if (environment.enableBugsnag) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.envName
  })
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserListComponent,
    UserCreateEditComponent,
    ToolCreateEditComponent,
    ToolEditForSubmitterComponent,
    ToolListComponent,
    ToolUseCaseCreateEditComponent,
    ToolUseCaseEditForSubmitterComponent,
    ToolUseCaseListComponent,
    MetricCreateEditComponent,
    MetricEditForSubmitterComponent,
    MetricListComponent,
    MetricUseCaseCreateEditComponent,
    MetricUseCaseEditForSubmitterComponent,
    MetricUseCaseListComponent,
    MetricDetailComponent,
    MetricUseCaseDetailComponent,
    ToolUseCaseDetailComponent,
    ToolDetailComponent,
    PolicyInitiativeCreateEditComponent,
    PolicyInitiativeDetailComponent,
    PolicyInitiativeListComponent,
    ProgrammingLanguageCreateEditComponent,
    ProgrammingLanguageListComponent,
    TagCreateEditComponent,
    TagListComponent,
    ToolTypeCreateEditComponent,
    ToolTypeListComponent,
    MetricTypeCreateEditComponent,
    MetricTypeListComponent,
    LifecycleCreateEditComponent,
    LifecycleListComponent,
    ImpactedStakeholderCreateEditComponent,
    ImpactedStakeholderListComponent,
    StakeholderGroupCreateEditComponent,
    StakeholderGroupListComponent,
    PurposeCreateEditComponent,
    PurposeListComponent,
    CoreApplicationAreaCreateEditComponent,
    CoreApplicationListComponent,
    LicenseCreateEditComponent,
    LicenseListComponent,
    TargetUserCreateEditComponent,
    TargetUserListComponent,
    TargetSectorCreateEditComponent,
    TargetSectorListComponent,
    TargetGroupCreateEditComponent,
    TargetGroupListComponent,
    UsageRightCreateEditComponent,
    UsageRightListComponent,
    MaturityCreateEditComponent,
    MaturityListComponent,
    OrganisationCreateEditComponent,
    OrganisationListComponent,
    ObjectiveCreateEditComponent,
    ObjectiveListComponent,
    ApproachCreateEditComponent,
    ApproachListComponent,
    GeographicalScopeCreateEditComponent,
    GeographicalScopeListComponent,
    EnforcementCreateEditComponent,
    EnforcementListComponent,
    BenefitCreateEditComponent,
    BenefitListComponent,
    PeopleInvolvedCreateEditComponent,
    PeopleInvolvedListComponent,
    RequiredSkillCreateEditComponent,
    RequiredSkillListComponent,
    TechnologyPlatformCreateEditComponent,
    TechnologyPlatformListComponent,
    ValidityCreateEditComponent,
    ValidityListComponent,
    ChangeHistoryComponent,
    CountryCreateEditComponent,
    CountryListComponent,
    RiskCreateEditComponent,
    RiskListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AbacusModule
  ],
  // providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
  bootstrap: [AppComponent],
  providers: [EventService, AuthService]
})
export class AppModule {}
