import { Status } from '../../../../../shared/enums/status.enum'
import { ActionType } from '../../abacus/enums/action-type.enum'
import { InputType } from '../../abacus/enums/input-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const metricUseCaseDefinition: ResourceDefinition = {
  title: 'Metric use cases',
  nameSingular: 'metric use case',
  namePlural: 'metric use cases',
  className: 'MetricUseCase',
  mainIdentifier: 'name',
  slug: 'metric-use-cases',
  path: 'metric-use-cases',
  icon: 'icon-file',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editMetricUseCases',
      action: (metricUseCase) => ({
        type: ActionType.Link,
        link: {
          path: `${metricUseCaseDefinition.path}/${metricUseCase.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteMetricUseCases',
      action: (metricUseCase) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: metricUseCase,
          definition: metricUseCaseDefinition
        }
      })
    }
  ],
  actionButtons: [
    {
      label: 'Publish',
      className: 'button is-success p-x-lg is-small',
      permission: 'editMetricUseCases',
      condition: (metricUseCase) => metricUseCase.status === Status.Pending,
      action: (metricUseCase) => ({
        type: ActionType.Patch,
        patch: {
          path: `metric-use-cases/${metricUseCase.id}/status`,
          body: { status: Status.Published },
          successMessage: 'The metric use case has been published',
          errorMessage: 'Error: metric use case could not be published',
          redirectTo: `metric-use-cases/${metricUseCase.id}`
        }
      })
    },
    {
      label: 'Reject',
      className: 'button is-danger ml-2 p-x-lg is-small',
      condition: (metricUseCase) => metricUseCase.status === Status.Pending,
      action: (metricUseCase) => ({
        type: ActionType.OpenCreateEditModal,
        openCreateEditModal: {
          title: `Reject the submission`,
          helpText: `You can provide a reason for rejecting the submission.`,
          definition: metricUseCaseDefinition,
          mode: ResourceMode.Patch,
          item: metricUseCase,
          patchURL: `metric-use-cases/${metricUseCase.id}/status`,
          fields: [
            {
              label: 'Rejection comment',
              property: 'rejectReason',
              placeholder: 'Explain why you are rejecting this submission.',
              className: 'is-12',
              inputType: InputType.Textarea
            },
            {
              label: 'Status',
              property: 'status',
              forcedValue: Status.Rejected,
              inputType: InputType.Select,
              hidden: true
            }
          ],
          flashMessageOnSuccess: 'The metric use case has been rejected',
          flashMessageOnError: 'Error: metric use case could not be rejected',
          redirectTo: `metric-use-cases/${metricUseCase.id}`
        }
      })
    }
  ]
}
