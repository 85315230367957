import { Route } from '@angular/router'

import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

import { TargetSectorCreateEditComponent } from './target-sector-create-edit/target-sector-create-edit.component'
import { TargetSectorListComponent } from './target-sector-list/target-sector-list.component'

export const targetSectorRoutes: Route[] = [
  {
    path: 'target-sectors',
    component: TargetSectorListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTaxonomies'
    }
  },
  {
    path: 'target-sectors/create',
    component: TargetSectorCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addTaxonomies'
    }
  },
  {
    path: 'target-sectors/:id/edit',
    component: TargetSectorCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editTaxonomies'
    }
  }
]
