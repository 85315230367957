import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { abacusConfig } from '../../abacus.config'
import { CaseCreateEditComponent } from '../../abacus/components/case-create-edit.component'
import { InputType } from '../../abacus/enums/input-type.enum'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { Field } from '../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../abacus/services/flash-message.service'
import { ResourceService } from '../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../abacus/templates/case-create-edit.template'
import { countryDefinition } from '../country/country.definition'
import { userDefinition } from './user.definition'

@Component({
  template: caseCreateEditTemplate
})
export class UserCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit, OnDestroy
{
  definition: ResourceDefinition = userDefinition
  isEditMyself: boolean

  // EditMyself only : Changing user's own email makes token obsolete.
  emailChanged = false

  fields: Field[] = [
    {
      id: 'name',
      label: 'Name',
      property: 'name',
      className: 'is-3',
      inputType: InputType.Text,
      required: true
    },
    {
      id: 'roleId',
      label: `Role`,
      property: 'roleId',
      retrievedItemProperties: {
        roleId: 'role.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.customResourceService.listSelectOptions('roles'),
      className: 'is-3',
      required: true,
      onChange: async (roleId: string) => {
        const role = await this.customResourceService.show('roles', roleId)

        const enableNotificationEmailsField: Field = this.getFieldById(
          'enableNotificationEmails'
        )

        if (role.name !== abacusConfig.adminRoleName) {
          enableNotificationEmailsField.hidden = true
        } else {
          enableNotificationEmailsField.hidden = false
          this.setFieldValue(enableNotificationEmailsField, false)
        }

        enableNotificationEmailsField.hidden =
          role.name !== abacusConfig.adminRoleName
      }
    },
    {
      id: 'email',
      label: 'Email',
      property: 'email',
      className: 'is-3',
      inputType: InputType.Email,
      required: true,
      validators: [Validators.email]
    },
    {
      label: 'Password',
      property: 'password',
      className: 'is-3',
      inputType: InputType.Password,
      createValidators: [Validators.required],
      editValidators: []
    },
    {
      label: 'Avatar',
      placeholder: 'Select an image...',
      property: 'image',
      className: 'is-3',
      inputType: InputType.Image
    },
    {
      label: 'Active',
      helpText: `Only active users can log in.`,
      property: 'isActive',
      initialValue: { value: false },
      className: 'is-3',
      inputType: InputType.Checkbox
    },
    {
      id: 'enableNotificationEmails',
      label: 'Enable notification emails',
      helpText: `Enable if you want to receive a notification each time an entry is submitted (admin only).`,
      property: 'enableNotificationEmails',
      initialValue: { value: false },
      className: 'is-3',
      inputType: InputType.Checkbox,
      hidden: true
    },
    {
      label: 'Color',
      property: 'color',
      className: 'is-6',
      inputType: InputType.ColorPicker,
      required: true
    },
    {
      label: 'Countries',
      properties: {
        countryIds: 'countryIds'
      },
      retrievedItemProperties: {
        countryIds: 'countryIds'
      },
      className: 'is-6',
      searchResources: [countryDefinition],
      inputType: InputType.MultiSearch
    }
  ]

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customBreadcrumbService: BreadcrumbService,
    private customFlashMessageService: FlashMessageService,
    private customRouter: Router,
    private customActivatedRoute: ActivatedRoute,
    private customResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
    this.isEditMyself = activatedRoute.snapshot.data.editMyself
  }

  async ngOnInit() {
    if (this.isEditMyself) {
      await this.initUserEditMyselfView()
    } else {
      await this.initCreateEditView()
    }

    if (
      this.mode === ResourceMode.Edit &&
      this.item.role.name === abacusConfig.adminRoleName
    ) {
      this.getFieldById('enableNotificationEmails').hidden = false
    }
  }

  // Special version of this form when user edits hisself or herself.
  async initUserEditMyselfView() {
    this.mode = this.customActivatedRoute.snapshot.data.mode
    this.fieldSpecialRules = [
      {
        fieldId: 'roleId',
        hidden: true
      },
      {
        fieldId: 'positionId',
        hidden: true
      }
    ]

    this.resolvedFields = await this.resolveFields(this.fields)

    this.item = await this.customResourceService
      .show(this.definition.slug, 'myself')
      .then((itemRes) => itemRes)
    this.item.id = 'myself'

    this.form = await this.generateForm(this.fields)

    this.form.valueChanges.subscribe((newValue: { email: string }) => {
      if (newValue.email !== this.item.email) {
        this.emailChanged = true
      }
    })

    this.customBreadcrumbService.breadcrumbLinks.next([
      {
        path: `/${this.definition.path || this.definition.slug}`,
        label: this.definition.title
      },
      {
        label: `Edit my profile`
      }
    ])
  }

  ngOnDestroy() {
    if (this.isEditMyself && this.emailChanged) {
      this.customRouter.navigate(['/logout'])
      this.customFlashMessageService.info(
        `Vous avez changé votre adresse email. Veuillez vous re-connecter avec votre nouvelle adresse.`
      )
    }
  }
}
