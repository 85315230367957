import { Component, Input, OnInit } from '@angular/core'
import { MetricUseCase } from 'src/app/abacus/interfaces/resources/metric-use-case.interface'
import { Metric } from 'src/app/abacus/interfaces/resources/metric.interface'
import { Record } from 'src/app/abacus/interfaces/resources/record.interface'
import { ToolUseCase } from 'src/app/abacus/interfaces/resources/tool-use-case.interface'
import { Tool } from 'src/app/abacus/interfaces/resources/tool.interface'
import { ResourceService } from 'src/app/abacus/services/resource.service'
import { Entity } from '../../../enums/entity.enum'

interface FieldChange {
  key: string
  valueBefore: string
  valueAfter: string
}

@Component({
  selector: 'app-change-history',
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.scss']
})
export class ChangeHistoryComponent implements OnInit {
  @Input() item: Metric | MetricUseCase | Tool | ToolUseCase
  @Input() entityType: Entity
  @Input() itemRelations: string[]

  records: Record[]
  detailedRecord: {
    date: string
    fieldChanges: FieldChange[]
  }

  showRecordList = false

  constructor(private resourceService: ResourceService) {}

  async ngOnInit() {
    this.records = await this.resourceService.list('records', {
      itemId: this.item.id,
      entity: this.entityType
    })
  }

  showRecord(
    record: Record,
    relations: string[]
  ): {
    date: string
    fieldChanges: FieldChange[]
  } {
    // Get data Before.
    var dataBefore: Metric | MetricUseCase | Tool | ToolUseCase =
      this.formatItem(JSON.parse(record.overwrittenData), relations)

    // Get data After.
    const recordIndex = this.records.indexOf(record)
    var dataAfter: Metric | MetricUseCase | Tool | ToolUseCase =
      this.formatItem(
        recordIndex === 0
          ? JSON.parse(JSON.stringify(this.item))
          : JSON.parse(this.records[recordIndex - 1].overwrittenData),
        relations
      )

    // Return object with differences between both.
    return {
      date: record.createdAt,
      fieldChanges: Object.keys(dataBefore).reduce(
        (fieldChanges: FieldChange[], key: string) => {
          if (dataBefore[key] !== dataAfter[key]) {
            fieldChanges.push({
              key: this.formatCamelCaseToSentenceCase(key),
              valueBefore: dataBefore[key],
              valueAfter: dataAfter[key]
            })
          }
          return fieldChanges
        },
        []
      )
    }
  }

  private formatItem(
    item: Metric | MetricUseCase | Tool | ToolUseCase,
    relations: string[]
  ): Metric | MetricUseCase | Tool | ToolUseCase {
    Object.entries(item).forEach(([key, value]) => {
      // Keys that end with 'Ids' or 'Id' are not relevant, so we delete them.
      if (key.endsWith('Ids') || key.endsWith('Id')) {
        delete item[key]
      }

      // If the key value include html tags, format it to string.
      if (typeof value === 'string') {
        item[key] = value.replace(/<[^>]*>/g, '')
      } else {
        if (relations.includes(key)) {
          if (!Array.isArray(value)) {
            item[key] = value.name
          } else {
            item[key] = value.length
              ? value.map((obj) => obj.name).join(', ')
              : null
          }
        }
      }
    })

    return item
  }

  private formatCamelCaseToSentenceCase(str: string): string {
    return str.replace(/([A-Z])/g, ' $1').trim()
  }
}
